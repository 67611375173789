import React from "react";
import moment from "moment";
import { isEmpty } from "lodash";

import { LabelArrow } from "../../../../../ui-kit/icons/LabelArrow";
import { DetailsIcon } from "../../../../../ui-kit/icons/DetailsIcon";
import { Typography } from "../../../../../ui-kit/components/Typography/Typography";
import { CalenderIcon } from "../../../../../ui-kit/icons/CalenderIcon";
import { EmailIcon } from "../../../../../ui-kit/icons/EmailIcon";
import { OptionArrowIcon } from "../../../../../ui-kit/icons/OptionArrowIcon";
import { NewLabelIcon } from "../../../../../ui-kit/icons/NewLabelIcon";

import { ShowReturn } from "../ShowReturn";
import { useSideBar } from "./SideBar.hook";

import "react-notifications-component/dist/theme.css";

export const SideBar = ({
  t,
  merchant,
  customer_return,
  customer,
  current_user,
  markStatus,
  showModelHandler,
  showCloseReturnModal,
  sendEmail,
  showSendEmail,
  showInstantStoreCreditCaptureAuthorizedPaymentModal,
  portalMagicLink,
  canClose,
  canCancel,
  isCompletionInProgress,
}) => {
  const { isInstantStoreCreditCaptureAuthorizedAmountPopupEligible } =
    useSideBar({
      customer_return,
    });

  return (
    <div className="flex-grow pt-8 pl-6">
      <div className="pr-7">
        <div className="flex items-center border-b justify-between pb-8">
          <div className="flex items-center">
            <Typography
              element="p"
              weight="semibold"
              size="base"
              height="6"
              color="gray-825"
              className="mr-3"
            >
              {customer_return.order_name || customer_return.order_number}
            </Typography>

            {merchant.shop_backend === "shopify_integration" && (
              <a
                href={`https://${merchant.shopify_domain}/admin/orders/${customer_return.shop_order_id}`}
                target="_blank"
              >
                <DetailsIcon />
              </a>
            )}
          </div>

          <div className="flex items-center">
            <CalenderIcon stroke="#A1A1AA" />

            <Typography
              element="p"
              weight="medium"
              size="xs13"
              height="4"
              color="gray-525"
              className="ml-2"
            >
              {moment(customer_return.created_at).format("MMM D YYYY")}
            </Typography>
          </div>
        </div>
      </div>

      <div className="py-5 border-b">
        {merchant.shop_backend === "shopify_integration" && (
          <div>
            {customer_return.exchange_order_id &&
              customer_return.draft_order_id && (
                <div className="mb-5 flex justify-between">
                  <Typography
                    element="p"
                    weight="medium"
                    size="xs13"
                    height="4"
                    color="gray-525"
                  >
                    {t.exchange_order_id}
                  </Typography>
                  <div className="flex w-7/12">
                    <Typography
                      element="p"
                      weight="medium"
                      size="xs13"
                      height="4"
                      color="gray-825"
                    >
                      {customer_return.exchange_order_id}
                    </Typography>
                    <a
                      href={`https://${merchant.shopify_domain}/admin/orders/${customer_return.exchange_order_id}`}
                      target="_blank"
                      className="ml-2"
                    >
                      <DetailsIcon />
                    </a>
                  </div>
                </div>
              )}

            {!customer_return.exchange_order_id &&
              customer_return.draft_order_id && (
                <div className="mb-5 flex justify-between">
                  <p className="font-medium text-xs13 leading-4 text-gray-525">
                    {t.draft_order_id}
                  </p>
                  <div className="flex w-7/12">
                    <Typography
                      element="p"
                      weight="medium"
                      size="xs13"
                      height="4"
                      color="gray-825"
                    >
                      {customer_return.draft_order_id}
                    </Typography>
                    <a
                      href={`https://${merchant.shopify_domain}/admin/draft_orders/${customer_return.draft_order_id}`}
                      target="_blank"
                      className="ml-2"
                    >
                      <DetailsIcon />
                    </a>
                  </div>
                </div>
              )}

            {customer_return.gift_card_order_id && (
              <div className="mb-5 flex justify-between">
                <Typography
                  element="p"
                  weight="medium"
                  size="xs13"
                  height="4"
                  color="gray-525"
                >
                  {t.store_credit_order_id}
                </Typography>
                <div className="flex w-7/12">
                  <Typography
                    element="p"
                    weight="medium"
                    size="xs13"
                    height="4"
                    color="gray-825"
                  >
                    {customer_return.gift_card_order_id}
                  </Typography>
                  <a
                    href={`https://${merchant.shopify_domain}/admin/orders/${customer_return.gift_card_order_id}`}
                    target="_blank"
                    className="ml-2"
                  >
                    <DetailsIcon />
                  </a>
                </div>
              </div>
            )}
          </div>
        )}

        {customer_return.payment_transaction_id && (
          <div className="mb-5 flex justify-between">
            <Typography
              element="p"
              weight="medium"
              size="xs13"
              height="4"
              color="gray-525"
            >
              {t.payment_transaction_id}
            </Typography>

            <div className="flex w-7/12">
              <Typography
                element="p"
                weight="medium"
                size="xs13"
                height="4"
                color="gray-825"
              >
                {customer_return.payment_transaction_id}
              </Typography>

              <a
                href={customer_return.payment_transaction_url}
                target="_blank"
                className="ml-2"
              >
                <DetailsIcon />
              </a>
            </div>
          </div>
        )}

        {customer_return.discount_codes &&
          customer_return.discount_codes
            .split(",")
            .map((code, index) => (
              <ShowReturn
                key={index}
                className="mb-5"
                title={t.discount_code}
                info={code}
                infoClass="w-7/12"
              />
            ))}
        {!isEmpty(customer_return.payment_methods) && (
          <ShowReturn
            className="mb-5"
            title={t.payment_method}
            info={customer_return.payment_methods
              .split(",")
              .map((method) => method.trim())
              .join(", ")}
            infoClass="w-7/12"
          />
        )}
        <ShowReturn
          title={t.window}
          info={customer_return.days_to_return + " " + t.days}
          infoClass="w-7/12"
        />

        {!isCompletionInProgress && (
          <div className="mt-5 flex w-full">
            <p className="w-5/12 font-medium text-xs13 leading-4 flex items-center text-gray-525">
              {t.status}
            </p>

            <div className="flex w-7/12">
              {customer_return.is_inspected && !customer_return.is_refunded && (
                <OptionArrowIcon />
              )}

              {!customer_return.is_inspected &&
              !customer_return.is_refunded &&
              customer_return.is_arrived ? (
                <select
                  className="w-100px font-medium text-xs leading-4 text-gray-825 focus:outline-none"
                  name="select"
                  onChange={markStatus}
                >
                  <option value="">{t.received}</option>
                  <option value="undo_is_arrived">{t.open}</option>
                </select>
              ) : (
                (!customer_return.is_inspected ||
                  customer_return.is_refunded) && (
                  <p className="font-medium text-xs13 leading-4 text-gray-825 capitalize">
                    {customer_return.status}
                  </p>
                )
              )}

              {customer_return.is_inspected && !customer_return.is_refunded && (
                <select
                  className="w-100px font-medium text-xs leading-4 text-gray-825 focus:outline-none ml-1"
                  name="select"
                  onChange={markStatus}
                >
                  <option value="">{t.needs_review}</option>
                  <option value="undo_is_arrived">{t.open}</option>
                  <option value="undo_is_inspected">{t.received}</option>
                </select>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="pt-5 pb-4 border-b">
        {customer_return.shipments.map((shipment, index) => (
          <div key={index}>
            <ShowReturn
              className="mb-5"
              title={t.carrier}
              info={shipment.shipping_method || shipment.shipping_company}
              returnMethodImage={
                shipment.merchant_return_method_image_url ||
                customer_return.merchant_return_method_image_url
              }
              infoClass="uppercase"
            />

            {shipment.label_url && (
              <div>
                <ShowReturn
                  className="mb-5"
                  title={t.tracking}
                  info={shipment.tracking_number}
                  trackingLink={shipment.tracking_link}
                  infoClass="w-7/12"
                />

                <ShowReturn
                  className="mb-5"
                  title="Label"
                  info="Download Label"
                  shipmentLabelUrl={shipment.label_url}
                >
                  <LabelArrow />
                </ShowReturn>

                <ShowReturn
                  className="mb-5"
                  title={t.created_at}
                  info={moment(shipment.created_at).format("MMM D YYYY")}
                  infoClass="w-7/12"
                />
              </div>
            )}
            {shipment.commercial_invoice_url && (
              <ShowReturn
                className="mb-5"
                title="Commercial Invoice"
                info="Download Commercial Invoice"
                commercialInvoiceUrl={shipment.commercial_invoice_url}
              >
                <LabelArrow />
              </ShowReturn>
            )}
          </div>
        ))}

        <div className="flex-cols">
          <div className="flex justify-between mb-3">
            <div></div>
            <div className="w-7/12">
              <button
                className="flex items-center focus:outline-none"
                onClick={showModelHandler}
              >
                <NewLabelIcon />

                <Typography
                  element="p"
                  weight="medium"
                  size="xs13"
                  height="4"
                  color="blue-675"
                >
                  {t.create_label}
                </Typography>
              </button>
            </div>
          </div>

          {showSendEmail && (
            <div className="flex justify-between">
              <div></div>
              <div className="w-7/12">
                <button
                  className="flex items-center focus:outline-none"
                  onClick={sendEmail}
                >
                  <EmailIcon />

                  <Typography
                    element="p"
                    weight="medium"
                    size="xs13"
                    height="4"
                    color="blue-675"
                  >
                    {t.email_shipping_label}
                  </Typography>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="pt-5 pb-4 border-b">
        <ShowReturn
          title={t.customer}
          info={customer.full_name}
          titleClass="inline-block"
          customerDetailUrl={`/customers/${customer.id}/edit`}
        >
          <DetailsIcon />
        </ShowReturn>

        <ShowReturn
          className="mt-5"
          title="Email"
          info={customer.email}
          infoClass="w-7/12"
        />
      </div>

      {portalMagicLink && (
        <div className="mt-6">
          <a
            href={portalMagicLink}
            target="_blank"
            rel="noreferrer"
            className="bg-blue-700 border box-border rounded-lg px-4 py-2 text-xs font-semibold leading-4 text-gray-100 focus:outline-none focus:shadow-btn_blue focus:border-blue-625 hover:border"
          >
            {t.register_new_return}
          </a>
        </div>
      )}

      {!isCompletionInProgress && (
        <div className="flex items-center mt-5 mb-40">
          {canCancel && !customer_return.is_closed && (
            <button
              className="bg-red-525 border rounded-lg font-semibold text-xs leading-4 text-white px-4 py-2 focus:outline-none focus:bg-red-525 hover:bg-red-800"
              name={customer_return.is_cancelled ? "undo" : "cancel"}
              onClick={(e) =>
                isInstantStoreCreditCaptureAuthorizedAmountPopupEligible() &&
                !customer_return.is_cancelled
                  ? showInstantStoreCreditCaptureAuthorizedPaymentModal({
                      actionName: "cancel",
                      actionCallback: markStatus,
                    })
                  : markStatus(e)
              }
            >
              {customer_return.is_cancelled ? t.undo : t.cancel}
            </button>
          )}

          {!customer_return.on_hold &&
            !customer_return.is_cancelled &&
            !customer_return.is_closed &&
            !customer_return.is_refunded && (
              <button
                className={`bg-white border border-gray-325 box-border rounded-lg px-4 py-2 font-semibold text-xs leading-4 text-gray-875 focus:outline-none focus:shadow-btn_blue focus:border-blue-625 hover:border hover:border-gray-425 ${
                  canCancel ? "ml-3" : ""
                }`}
                name="put_on_hold"
                onClick={markStatus}
              >
                {t.put_on_hold}
              </button>
            )}

          {canClose &&
            !customer_return.is_cancelled &&
            !customer_return.on_hold &&
            !customer_return.is_closed &&
            !customer_return.is_refunded &&
            current_user.role !== "warehouse2" && (
              <button
                className="bg-white border border-gray-325 box-border rounded-lg ml-3 px-4 py-2 font-semibold text-xs leading-4 text-gray-875 focus:outline-none focus:shadow-btn_blue focus:border-blue-625 hover:borderd hover:border-gray-425"
                onClick={showCloseReturnModal}
              >
                {t.close}
              </button>
            )}
        </div>
      )}
    </div>
  );
};
